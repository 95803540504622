export const LANG_OLD = {
  en: {
    // menu and module titles
    APP: "Mola - Fight Predictor",
    SLOGAN: "Ride for Good!", // 'The place for soccer enthusiasts.'
    WELCOME: "WELCOME TO Mola - Fight Predictor!",
    WELCOME_TEXT_UPDATED: "Welcome Text Updated",
    ONLINE: " You are online.",
    OFFLINE: " Connection lost, you are offline!",
    LANG: "Language",
    ABOUT: "About Mola - Fight Predictor",
    ABOUT_TEXT: "Developed by Martin Kuijs",
    ABOUT_VERSION: "Version:",

    // Get started
    LANDING: "",

    // Signup
    YOUR_NUMBER: "What's your number?",
    THE_CODE: "What's the code?",
    WE_TEXT: "We will text you a code to verify",
    SMS_SENT: "Sent to: ",
    ENTER_CODE: "Enter the code",
    RE_SEND: "Resend Code",
    PROBLEMS: "Problems receiving the code?",
    INVALID_CODE: "Invalid SMS code format!",
    INV_CODE: "Invalid code!",
    CODE_MUST_BE: "The code must be 6 numbers",
    CHECK_SMS: "Please check your SMS or resend the code.",
    CHECK_NR: "Please check your number.",
    SMS_ERROR: "Error. SMS not sent!",
    ALREADY_ACCOUNT: "Already have an account?",
    LOGIN_EMAIL: "Login with your email address",
    NEW_PHONE: "New phone number?",
    CHANGE_PHONE: "Change your phone number here",

    // Sign Up Next
    IN_ORDER:
      "In order to complete the setup of your account, we need a little more information from you...",
    ACCOUNT_CREATED: "Account created successfully.",
    CONGRATULATIONS: "WELCOME TO",
    CONGRATULATIONS_A: "Mola - Fight Predictor!",
    ACC_READY:
      "Your account has been successfully registered! You can now sign in on any device with the mobile number or email you’ve provided.",
    ACC_TEMP_EMAIL:
      "In the event you’ve changed your mobile number, please use either your personal email or the Mola - Fight Predictor email provided to you in Your Account to log in again.",
    NO_EMAIL: "I don't have an email address",
    SET_PASSWORD: "Please, set your password",
    AGREE_TERMS: "I agree to the Terms and Privacy Policy",

    // Sign In
    SIGN_IN_HERE: "Please, Sign In here",
    PW_RECOVERY: "Password Recovery",
    ENTER_EMAIL: "Please, enter your email address",
    ENTER_NEW_EMAIL: "Please, enter your new email address",
    CHANGE_PHONE_NR: "Change Your Phone Number",
    SIGN_IN_FIRST: "Please, Sign In first",
    FORGOT_PWD: "Forgot your password?",
    NO_ACCOUNT: "No Account yet?",
    SIGN_UP_NOW: "Sign Up now!",
    SEND_RESET_LINK: "We will send a reset link to your email.",
    EMAIL_SENT: "Email Sent",
    CHECK_EMAIL: "Please check your inbox, an email is on the way.",
    EMAIL_INSTRUCTIONS:
      "All you have to do is open it and click the link. Of course, if you have any questions, you can contact us.",

    // Home
    HOME: "Home",
    ABOUT_US: "About Us",
    MODEL: "Model",
    NEWS_REEL: "Sustainability Stories",
    BIKES: "Bikes",
    CAREERS: "Mission Roles",
    TEAM: "Team",
    CONTACT: " Contact",
    CONTACT_TITLE: "Contact Information and Useful Links",
    CONTACT_SUB_TITLE:
      "Learn more about Mola - Fight Predictor, connect with the team and the community.",
    ADMIN_HOME: "Admin Home",
    APPLY_FOR_A_JOB: "Apply for a Job",

    // Careers
    CAREERS_HEADER: "Welcome To Our Impact Journey!",

    // User Menu
    MY_ACCOUNT: "My Account",
    ACC_VERIFICATION: "Account Verification",
    ACC_EMAIL: "Account Email",
    ACC_PHONE: "Account Phone",
    ACC_ID: "Identification",
    ACC_UPLOAD_KTP: "Upload ID Document",
    ACC_TYPE: "Account Type",
    ACC_MY_VEHICLES: "My Velicle(s)",
    ACC_TIER: "Account Tier",
    ACC_STATUS: "Account Status",
    ACC_INFO: "Account Information",
    ACC_PERSONAL: "Personal Information",
    ACC_NAME: "Display Name",
    ACC_ABOUT: "About me",
    ACC_ABOUT_TEXT: "Write something about yourself...",
    ACC_FULL_NAME: "Full Legal Name",
    ACC_DOB: "Date of Birth",
    ACC_DOB_TEXT: "Add your birthday",
    ACC_GENDER: "Gender",
    ACC_MALE: "Male",
    ACC_FEMALE: "Female",
    ACC_NOT_SET: "Not Set",
    ACC_LANG: "Language",
    ACC_OB: "Mola - Fight Predictor Settings",
    ACC_TEAMS: "My Favourite Teams",
    ACC_LEAGUES: "My Leagues",
    ACC_CH_PIC: "Change Profile Picture",
    ACC_SET_PIC: "Set as Profile Picture",
    ACC_EMAIL_NOT_SET: "Your email adress has not been set",
    ACC_EMAIL_ASS:
      "We have assigned you a temporary email address that is used for auhtentication together with your connected wallet.",
    ACC_NOT_VERIFIED: "Your email adress has not been verified",
    ACC_VERIFIED: "Your email adress has been verified",
    ACC_VERIFY: "Please follow the link below to verify your email.",
    ACC_APP: "App",
    ACC_APP_VER: "Current Version",
    ACC_APP_TERMS: "Terms and Conditions",
    ACC_APP_PRIVACY: "Privacy Policy",
    ACC_MOLA_SUBS: "Mola Subscription",
    // Manage Blogs
    MANAGE_BLOGS: "Manage Blogs",
    MANAGE_TEST_RIDES: "Manage Test Rides",
    BLOG_CREATED: "News Article Created",
    BLOG_UPDATED: "Blog  Updated",
    MAX_FILES: "Maximal 5 Files per batch",
    MAX_FILESIZE: "Maximum file size of 7 MB exeeded for: ",
    RECORD_DELETED: "Record Deleted",
    MAX_FILESIZE_F: "Maximum file size is 500 KB!",
    BLOG_SEE_MORE: "Load More >",
    BLOG_SIGN_UP: "Sign In / Sign Up to post comments",

    // Job POSTER MENU
    POST_JOBS: "Post Jobs",
    MANAGE_POSTED_JOBS: "Manage Posted Jobs",
    COMPANY_ACCOUNT: "Company Account",
    JOBS_POSTED: "Jobs Posted",
    JOBS_POSTED_TEXT: "Manage jobs posted by you",
    NEW_APPLICATIONS: "New Applications",
    NEW_APPLICATIONS_TEXT: "Applications that need to be reviewed",
    CANDIDATES_HIRED: "Candidates Hired",
    CANDIDATES_HIRED_TEXT: "Candidates hired by you",
    JOB_POST_CREATED: "Job Post Created",
    JOB_POST_UPDATED: "Job Post Updated",

    // Careers - Vacancies
    SEARCH_JOBS: "Search jobs...",
    SEARCH_RESULTS: "Search results for",
    AVAILABLE_JOBS: "Open Vacancies",
    REQUIREMENTS: "Requirements",
    POSTION_TYPE: "Position Type",
    EDUCATION_LEVEL: "Education Level",
    EXPERIENCE_LEVEL: "Experience Level",
    MY_RESUME: "My Resume",
    USE_RESUME: "Provide a link to your resume (Google Drive, Dropbox etc)",
    MY_RESUME_VIDEO: "Introduction Video",
    USE_RESUME_VIDEO:
      "Record a short introduction video of yourself and provide a link. (Youtube, Google Drive, Dropbox etc)",
    ACTIVE_RESUME: "Active Resume",
    MOTIVATION: "Motivation",
    MOTIVATION_A: "Tell us why we should hire you...",

    // Apply
    APPLY_FOR: "Apply for",
    APPLY_JOBS: "How to Apply for a Job?",
    JOB_DETAILS: "Job Details",
    ALREADY_APPLIED: "You have applied for this Job",

    // Job Seeker
    ACCOUNT_REQ: "Registration is Required",
    ACCOUNT_REQ_TEXT:
      "In order to Apply to this Job, please Sign In to your account. If you don't have a Mola - Fight Predictor account yet, you can Sign Up following the link bellow.",
    DO_LATER: "I'll do this later",
    JOB_APPLICATION_SUBMITTED: "Job Application Submitted",

    // SEEKER MENU
    MY_APPLICATIONS: "Job Applications",
    MY_APPLICATIONS_U: "MY APPLICATIONS",
    MY_APPLICATIONS_TEXT: "Check for any updates",
    VERIFICATION: "VERIFICATION",
    MY_PROFILE: "My Profile",
    COMPLETE_PROFILE: "Please, complete your profile",
    NEW_JOBS: "NEW JOBS",
    NEW_JOBS_TEXT: "Find new jobs suited for you",

    // Contact Us
    CONTACT_US: "Contact Us",
    CONTACT_US_DROP: "Drop us a line.",

    // Settings
    SETTINGS: "Settings",
    PRO_CH_PL: "Please, set your password",
    SET_UPDATED: "Settings Updated",
    PWD_CHANGED: "Password has been changed",

    // Buttons
    BUT_APPLY_NOW: "Apply Now",
    BUT_RECENT: "RECENT",
    BUT_NEXT: "Next",
    BUT_CONTINUE: "Continue to your account",
    BUT_CONT_DRAFT: "Continue Writing",
    BUT_SIGN_IN: "Sign In",
    BUT_SIGN_UP: "Sign Up",
    BUT_RESET_PW: "Reset Password",
    BUT_CANCEL: "Cancel",
    BUT_BACK: "Back",
    BUT_BACK_TO_SIGN_IN: "Back to Sign In",
    BUT_VERIFY: "Verify",
    BUT_LATER: "I'll do this later",
    BUT_CHANGE_EMAIL: "Change your email address",
    BUT_CHANGE: "Change",
    BUT_DONE: "Done",
    BUT_OK: "OK",
    BUT_SAVE_DRAFT: "Save as Draft",
    BUT_SUBMIT: "Submit",
    BUT_CREATE_MORE: "Create another Job",
    BUT_SUBM_PHOTO: "Submit Photos",
    BUT_VIEW_ALL: "View Detail",
    BUT_ALL_NEWS: "See All News",
    BUT_ALL_VIDEOS: "See All Videos",
    BUT_ALL_FF: "See All in Fan Forum",
    BUT_VIEW_GRID: "View Grid",
    BUT_CONFIRM: "Confirm",
    BUT_CLOSE: "Close",
    BUT_CHECK: "Check Status",
    BUT_CHECK_PHOTO: "Check Publishing Status",
    BUT_LOAD_MORE: "Load more...",
    BUT_COMMON_CONTINUE: "Continue",
    BUT_COMPLETE: "Complete",

    //HOVER
    HOVER_TC: "Open terms and conditions!",

    //Validation
    VALIDATION_NAME_REQ: "Name is required",
    VALIDATION_EMAIL_REQ: "Email is required",
    VALIDATION_PHONE_REQ: "Phone is required",
    VALIDATION_EMAIL_VALID: "Email must be valid",
    VALIDATION_TC_AGREE: "You must agree to continue!",
    VALIDATION_FORM_VALID: "Your form is not valid.",

    //Common Labels
    LABEL_FULL_NAME: "Full Name",
    LABEL_FIRST_NAME: "First Name",
    LABEL_LAST_NAME: "Last Name",
    LABEL_EMAIL: "Email",
    LABEL_PHONE: "Phone",
    LABEL_DATE: "Date",
    LABEL_TIME: "Time",
    LABEL_AGREE: "I have agree to ",
    LABEL_TC: "Terms and Conditions",
    LABEL_FROM_CA: " from Mola - Fight Predictor",

    //Test Drive
    TEST_DRIVE_SAVED: "Your test ride record has been saved.",
    TEST_DRIVE_CANCEL: "Your test ride record has been canceled.",
    TEST_DRIVE_RESCHEDULE: "Your test ride record has been rescheduled.",
    TEST_DRIVE_PERSONAL_DATA: "Personal Information",
    TEST_DRIVE_SCHEDULE_DATA: "Appointment Schedule",
    TEST_DRIVE_REVIEW_DATA: "Review",

    //About
    ABOUT_TITLE: "Redefine <br />Your Way of Riding",
    ABOUT_TITLE_MOBILE: "Redefine <br />Your Way of <br />Riding",
    ABOUT_DESCRIPTION_1:
      "Our constitution enshrines that we act as a B(Beneficial) Corporation and meet the highest standards of verified social and environmental performance, public transparency and legal accountability to balance profit and purpose.<br /><br /> We are required to prioritize our mission to lead the adoption of sustainable mobility, monetary profit and economic growth equally with the welfare of all our stakeholders including but not limited to our employees, customers, partners, suppliers, community and environmental concerns.<br /><br /> Our mission at Mola is 10 million electric vehicles in 10 years. We plan to start with electric motorcycles and lead the mass adoption of sustainable mobility in our region.<br /><br /> We excel by balancing profit and purpose. We act as a B(Beneficial) corporation and meet the highest standards of verified social and environmental performance, public transparency and legal accountability.<br /><br /> We welcome everyone who shares our vision to join us on this impact journey!",
    ABOUT_CAROUSEL_TITLE: "Our mission at Mola - Fight Predictor",
    ABOUT_CAROUSEL_CONTENT: [
      {
        title: "Ten Million Vehicles in Ten Years",
        content:
          "At Mola, We're on a mission to increase electric vehicle adoption by making EVs more affordable and accessible. We believe electric is the future of transportation. we believe that electric vehicles will be a game changer. They will be common vehicle in the next ten years, therefore this is our mission to make 10 million electric vehicles in 10 years.",
      },
      {
        title: "Mola the Game Changer",
        content:
          "Our Mission is to be the system that moves people. Combining the beauty of design, the intelligence of software, and the kinetic energy created from perfectly balanced wheels. We’re creating a world where cities breathe easier and noise pollution is a thing of the past. Where driving is something everyone can enjoy. A world that recharges everyone it touches.",
      },
      {
        title: "Beneficial For Environment",
        content:
          "The vehicle that changed everything is back and better than ever. All-electric. No gas. And zero emissions. The key to getting the maximum benefit from your energy-efficient vehicle is ensuring that its power systems are running at peak efficiency.",
      },
    ],

    //Press Launch State
    PL_DESCRIPTION_TITLE: "Mola Indonesia is here, for good",
    PL_DESCRIPTION_TEXT:
      "We are here as a direct-to-user scale-up motorcycle manufacturer and distributor to provide sustainable, accessible and practical mobility for Indonesian customers. Our vehicles are competitive in price, performance and quality when compared to traditional combustion motorcycles. <br /> <br /> As a brand, we aim to accelerate the adoption of electric vehicles in Indonesia in order to mitigate air pollution and improve the overall mobility experience for Indonesians. <br /> <br /> As a company, we strive to operate as a B Corporation, meeting the highest standards of verified social and environmental performance, public transparency and legal accountability to balance profit and purpose.<br /> <br />We are close to rolling out our 16,000 square metre zero energy facility in Greater Jakarta to house the Mola experiential centre, research and development labs and production engineering facilities. ",
    PL_COMMITMENT_TITLE: "We believe in",
    PL_COMMITMENT_TEXT:
      "People, ideas and providing a world-class service that is consistent and ever-improving. Our team is dedicated to constantly developing new solutions and experiences so that you can continue enjoying your ride. <br /> <br /> Designing better services, products and experiences means planning for a better future by transitioning to clean and sustainable mobility. We envision a near future where we, and our generations to come, can indulge in cleaner air, clearer waters, and peaceful city streets. <br /> <br /> We are achieving this through providing sustainable mobility, working together with local communities and NGOs and ensuring long lasting relationships with our stakeholders. <br /><br />Join us, Ride for good with Mola.",
    PL_CONTACT_TITLE:
      "Want to know more? <br/> Chat with us for more information.",
    PL_CONTACT_TEXT:
      "You can also leave us a call on <a style='color: white;' href='https://api.whatsapp.com/send?phone=6281237686970&text=Hello%20Mola' target='_blank'>+62 812 37 686970</a> or write to us on <a style='color: white;' href='mailto:contact@mola.asia' target='_blank'>contact@mola.asia</a>",
    PL_CONTACT_TEXT_MOBILE:
      "You can also leave us a call on <a style='color: white;' href='https://api.whatsapp.com/send?phone=6281237686970&text=Hello%20Mola' target='_blank'>+62 812 37 686970</a> <hr class='my-4'/> write to us on <a style='color: white;' href='mailto:contact@mola.asia' target='_blank'>contact@mola.asia</a>",
    PL_CONTACT_BUTTON: "Start a Chat",
  },
  id: {
    // menu and module titles
    APP: "Mola - Fight Predictor",
    SLOGAN: "Ride for Good!",
    WELCOME: "SELAMAT DATANG DI Mola - Fight Predictor!",
    WELCOME_TEXT_UPDATED: "Teks Diperbarui",
    ONLINE: "Anda sedang terhubung!",
    OFFLINE: "Oops, koneksi anda terputus!",
    LANG: "Language",
    ABOUT: "About Mola - Fight Predictor",
    ABOUT_TEXT: "Developed by Martin Kuijs",
    ABOUT_VERSION: "Version:",

    // Get started
    LANDING: "Penggalangan dana untuk proyek dan orang yang berarti bagi Anda",

    // Signup
    YOUR_NUMBER: "Masukkan nomor telepon Anda?",
    THE_CODE: "Apa kodenya?",
    WE_TEXT:
      "Kami akan mengirimkan kodenya melalui SMS untuk proses verifikasi",
    SMS_SENT: "Dikirim ke: ",
    ENTER_CODE: "Masukkan kodenya",
    RE_SEND: "Kirim ulang kode",
    PROBLEMS: "Ada masalah saat menerima kode?",
    INVALID_CODE: "Format kode SMS salah!",
    INV_CODE: "Kode tidak valid!",
    CODE_MUST_BE: "Kode harus terdiri dari 6 angka",
    CHECK_SMS: "Silahkan cek SMS Anda atau kirim ulang kodenya.",
    CHECK_NR: "Silahkan cek nomor ponsel Anda.",
    SMS_ERROR: "Terjadi kesalahan. SMS tidak terkirim!",
    ALREADY_ACCOUNT: "Sudah memiliki akun?",
    LOGIN_EMAIL: "Silahkan login dengan email Anda",
    NEW_PHONE: "Memiliki nomor ponsel yang baru?",
    CHANGE_PHONE: "Ubah nomor ponsel Anda disini",

    // Sign Up Next
    IN_ORDER:
      "Untuk melengkapi proses pembuatan akun, kami membutuhkan sedikit informasi tentang Anda...",
    ACCOUNT_CREATED: "Akun sudah berhasil dibuat",
    CONGRATULATIONS: "SELAMAT DATANG DI",
    CONGRATULATIONS_A: "Mola - Fight Predictor!",
    ACC_READY:
      "Akun Anda sudah berhasil teregistrasi! Silahkan masuk dengan perangkat apa pun menggunakan nomor ponsel atau alamat email yang terdaftar.",
    ACC_TEMP_EMAIL:
      "Jika Anda telah mengubah nomor ponsel Anda, harap gunakan email pribadi Anda atau email Mola - Fight Predictor yang diberikan kepada Anda di Akun Anda untuk masuk kembali.",
    NO_EMAIL: "Saya tidak punya alamat email",
    SET_PASSWORD: "Silahkan buat password Anda",
    AGREE_TERMS: "Saya mengetujui persyaratan dan kebijakan privasi",

    // Sign In
    SIGN_IN_HERE: "Silahkan, login disini",
    PW_RECOVERY: "Pemulihan Kata Sandi",
    ENTER_EMAIL: "Silahkan ketik alamat email Anda",
    ENTER_NEW_EMAIL: "Silahkan ketik alamat email baru.",
    CHANGE_PHONE_NR: "Ubah nomor ponsel Anda",
    SIGN_IN_FIRST: "Silahkan login terlebih dahulu",
    FORGOT_PWD: "Lupa password?",
    NO_ACCOUNT: "Belum punya akun?",
    SIGN_UP_NOW: "Daftar sekarang!",
    SEND_RESET_LINK: "Kami akan mengirimkan link reset ke email Anda.",
    EMAIL_SENT: "Email terkirim",
    CHECK_EMAIL: "Silahkan cek inbox Anda, kami baru saja mengirimkan email.",
    EMAIL_INSTRUCTIONS:
      "Silahkan cek inbox Anda, kami baru saja mengirimkan email. Coba buka email dari kami dan klik link yang tersedia. Tentu saja, jika Anda memiliki pertanyaan, silahkan hubungi kami.",

    // Home
    HOME: "Home",
    ABOUT_US: "Tentang Kami",
    MODEL: "Model",
    NEWS_REEL: "Cerita Keberlanjutan",
    BIKES: "Sepeda",
    CAREERS: "Mengemban Misi",
    TEAM: "Tim",
    CONTACT: " Kontak",
    CONTACT_TITLE: "Informasi Kontak dan Tautan Berguna",
    CONTACT_SUB_TITLE:
      "Pelajari lebih lanjut tentang Mola - Fight Predictor, terhubung dengan tim dan komunitas.",
    ADMIN_HOME: "Admin Home",
    APPLY_FOR_A_JOB: "Melamar Pekerjaan",

    // Careers
    CAREERS_HEADER: "Marilah Mencapai Tujuan Bersama Kami!",

    // User Menu
    MY_ACCOUNT: "Akun Saya",
    ACC_VERIFICATION: "Verifikasi akun",
    ACC_EMAIL: "Email akun",
    ACC_PHONE: "Nomor ponsel ",
    ACC_ID: "Identifikasi",
    ACC_UPLOAD_KTP: "Ungga KTP",
    ACC_TYPE: "Tipe akun",
    ACC_MY_VEHICLES: "",
    ACC_TIER: "Akun Tier",
    ACC_STATUS: "Status akun",
    ACC_INFO: "Informasi akun",
    ACC_PERSONAL: "Informasi pribadi",
    ACC_NAME: "Nama tampilan",
    ACC_ABOUT: "Tentang saya",
    ACC_ABOUT_TEXT: "Tulis sesuatu tentang diri Anda...",
    ACC_FULL_NAME: "Nama lengkap",
    ACC_DOB: "Tanggal lahir",
    ACC_DOB_TEXT: "Masukkan tanggal lahir Anda",
    ACC_GENDER: "Jenis kelamin",
    ACC_MALE: "Pria",
    ACC_FEMALE: "Perempuan",
    ACC_NOT_SET: "Belum diatur",
    ACC_LANG: "Bahasa",
    ACC_OB: "Pengaturan Mola - Fight Predictor",
    ACC_TEAMS: "Tim Favorit Saya",
    ACC_LEAGUES: "Liga pilihan saya",
    ACC_CH_PIC: "Ganti Foto Profil",
    ACC_SET_PIC: "Simpan Gambar Profil",
    ACC_EMAIL_NOT_SET: "Email Anda belum di set",
    ACC_EMAIL_ASS:
      "Kami telah memberikan Anda alamat email yang hanya dapat digunakan untuk login bersama dengan kata sandi yang Anda buat.",
    ACC_NOT_VERIFIED: "Email Anda belum diverifikasi",
    ACC_VERIFIED: "Email Anda sudah diverifikasi",
    ACC_VERIFY: "Silahkan klik link dibawah ini untuk verifikasi email Anda.",
    ACC_APP: "App",
    ACC_APP_VER: "Versi",
    ACC_APP_TERMS: "Syarat dan Ketentuan",
    ACC_APP_PRIVACY: "Kebijakan Privasi",
    ACC_MOLA_SUBS: "Langganan Mola",

    // Manage Blogs
    MANAGE_BLOGS: "Manage Blogs",
    MANAGE_TEST_RIDES: "Manage Test Rides",
    BLOG_CREATED: "Blog Created",
    BLOG_UPDATED: "Blog Updated",
    MAX_FILES: "Maksimal 5 file untuk sekali upload",
    MAX_FILESIZE: "Ukuran maksimum file adalah 7 MB: ",
    RECORD_DELETED: "Item Dihapus",
    MAX_FILESIZE_F: "Maximum file size is 500 KB!",
    BLOG_SEE_MORE: "Load More >",
    BLOG_SIGN_UP: "Masuk / Daftar untuk mengirim komentar",

    // POSTER MENU
    POST_JOBS: "Posting Lowongan",
    MANAGE_POSTED_JOBS: "Atur Lowongan Kerja",
    COMPANY_ACCOUNT: "Akun Perusahaan",
    JOBS_POSTED: "Pekerjaan Diposting",
    JOBS_POSTED_TEXT: "Atur pekerjaan yang diposting oleh Anda",
    NEW_APPLICATIONS: "Aplikasi Baru",
    NEW_APPLICATIONS_TEXT: "Aplikasi yang perlu ditinjau",
    CANDIDATES_HIRED: "Kandidat Dipekerjakan",
    CANDIDATES_HIRED_TEXT: "Kandidat yang dipekerjakan oleh Anda",
    JOB_POST_CREATED: "Posting Pekerjaan Dibuat",
    JOB_POST_UPDATED: "Posting Pekerjaan Diperbarui",

    // Careers - Vacancies
    SEARCH_JOBS: "Cari lowongan...",
    SEARCH_RESULTS: "Hasil Pencarian",
    AVAILABLE_JOBS: "Lowongan Tersedia",
    REQUIREMENTS: "Persyaratan",
    POSTION_TYPE: "Jenis Posisi",
    EDUCATION_LEVEL: "Pendidikan",
    EXPERIENCE_LEVEL: "Pengalaman",
    MY_RESUME: "Resume Saya",
    USE_RESUME: "Berikan tautan ke resume Anda (Google Drive, Dropbox etc.)",
    MY_RESUME_VIDEO: "Introduction Video",
    USE_RESUME_VIDEO:
      "Record a short introduction video of yourself and provide a link. (Youtube, Google Drive, Dropbox etc)",
    ACTIVE_RESUME: "Resume Aktif",
    MOTIVATION: "Surat motivasi",
    MOTIVATION_A: "Beri tahu kami mengapa kami harus mempekerjakan Anda ...",

    // Apply
    APPLY_FOR: "Apply for",
    APPLY_JOBS: "Bagaimana Cara Melamar Pekerjaan?",
    JOB_DETAILS: "Detail Pekerjaan",
    ALREADY_APPLIED: "Anda telah melamar Pekerjaan ini",

    // Job Seeker
    ACCOUNT_REQ: "Anda harus mendaftar terlebih dahulu",
    ACCOUNT_REQ_TEXT:
      "Untuk melamar pekerjaan, silahkan masuk dahulu ke akun Anda. Jika Anda belum memiliki akun, silahkan daftar dengan cara klik link dibawah ini.",
    DO_LATER: "Lakukan ini nanti",
    JOB_APPLICATION_SUBMITTED: "Lamaran Pekerjaan Diserahkan",

    // SEEKER MENU
    MY_APPLICATIONS: "Aplikasi Saya",
    MY_APPLICATIONS_U: "APLIKASI SAYA",
    MY_APPLICATIONS_TEXT: "Periksa pembaruan apa pun",
    VERIFICATION: "VERIFIKASI",
    MY_PROFILE: "Profil Saya",
    COMPLETE_PROFILE: "Silakan Lengkapi Profilmu",
    NEW_JOBS: "PEKERJAAN BARU",
    NEW_JOBS_TEXT: "Temukan pekerjaan baru untuk Anda",

    // Contact Us
    CONTACT_US: "Hubungi Kami",
    CONTACT_US_DROP: "Kirimi kami pesan.",

    // Settings
    PRO_CH_PL: "Silahkan buat password Anda",
    SET_UPDATED: "Pengaturan Disimpan",
    PWD_CHANGED: "Kata sandi telah diubah",

    // Buttons
    BUT_APPLY_NOW: "Ajukan Sekarang",
    BUT_RECENT: "RECENT",
    BUT_NEXT: "Lanjut",
    BUT_CONTINUE: "Lanjutkan ke akun Anda",
    BUT_CONT_DRAFT: "Lanjut Menulis",
    BUT_SIGN_IN: "Masuk",
    BUT_SIGN_UP: "Daftar",
    BUT_RESET_PW: "Reset Password",
    BUT_CANCEL: "Cancel",
    BUT_BACK: "Kembali",
    BUT_BACK_TO_SIGN_IN: "Kembali ke Masuk",
    BUT_VERIFY: "Verifikasi",
    BUT_LATER: "I'll do this later",
    BUT_CHANGE_EMAIL: "Ubah alamat email Anda",
    BUT_CHANGE: "Ubah",
    BUT_DONE: "Selesai",
    BUT_OK: "OK",
    BUT_SAVE_DRAFT: "Simpan sebagai draft",
    BUT_SUBMIT: "Submit",
    BUT_CREATE_MORE: "Buat Pekerjaan Lain",
    BUT_SUBM_PHOTO: "Submit Foto",
    BUT_VIEW_ALL: "Lihat Detail",
    BUT_ALL_NEWS: "Lihat Semua Berita",
    BUT_ALL_VIDEOS: "Lihat Semua Video",
    BUT_ALL_FF: "Lihat Semua Fan Forum",
    BUT_VIEW_GRID: "Lihat Grid",
    BUT_CONFIRM: "Konfirmasi",
    BUT_CLOSE: "Tutup",
    BUT_CHECK: "Cek Status",
    BUT_CHECK_PHOTO: "Cek Status Penerbitan",
    BUT_LOAD_MORE: "Muat lebih banyak...",
    BUT_COMMON_CONTINUE: "Lanjutkan",
    BUT_COMPLETE: "Selesaikan",

    //HOVER
    HOVER_TC: "Buka syarat dan ketentuan!",

    //Validation
    VALIDATION_NAME_REQ: "Nama wajib diisi",
    VALIDATION_EMAIL_REQ: "Email wajib diisi",
    VALIDATION_PHONE_REQ: "Nomor Handphone wajib diisi",
    VALIDATION_EMAIL_VALID: "Email harus valid",
    VALIDATION_TC_AGREE: "Anda harus menyetujui syarat dan kondisi yang ada",
    VALIDATION_FORM_VALID: "Form yang anda isi belum valid.",

    //Common Labels
    LABEL_FULL_NAME: "Nama Lengkap",
    LABEL_FIRST_NAME: "Nama Depan",
    LABEL_LAST_NAME: "Nama Belakang",
    LABEL_EMAIL: "Email",
    LABEL_PHONE: "Nomor Handphone",
    LABEL_DATE: "Tanggal",
    LABEL_TIME: "Waktu",
    LABEL_AGREE: "Saya setuju dengan ",
    LABEL_TC: "Syarat dan Ketentuan",
    LABEL_FROM_CA: " dari Mola - Fight Predictor",

    //Test Drive
    TEST_DRIVE_SAVED: "Data test ride (uji jalan) berhasil disimpan.",
    TEST_DRIVE_CANCEL: "Data test ride (uji jalan) berhasil dibatalkan.",
    TEST_DRIVE_RESCHEDULE:
      "Data test ride (uji jalan) berhasil dijadwal ulang.",
    TEST_DRIVE_PERSONAL_DATA: "Data Diri",
    TEST_DRIVE_SCHEDULE_DATA: "Jadwal Pertemuan",
    TEST_DRIVE_REVIEW_DATA: "Ulasan Data",

    //About
    ABOUT_TITLE: "Ubah Kebiasaan <br />Bekendara Anda",
    ABOUT_TITLE_MOBILE: "Ubah Kebiasaan <br />Bekendara Anda",
    ABOUT_DESCRIPTION_1:
      "Misi dan tujuan kami di Mola - Fight Predictor adalah memproduksi 10 juta kendaraan listrik dalam jangka waktu 10 tahun.<br /><br /> Kami berinovasi untuk masa depan produksi sepeda motor listrik, dan mengedepankan berkendara secara massal serta berkelanjutan untuk seluruh wilayah di Indonesia.<br /><br /> Mola - Fight Predictor merupakan Perusahaan yang mengutamakan keseimbangan antara tehnologi dan tujuan masa depan. Perusahaan kami mengedepankan keunggulan produksi serta memenuhi standar tertinggi dalam lingkup kinerja sosial, lingkungan yang terverifikasi, transparansi publik, dan akuntabilitas hukum.<br /><br /> Kami mengutamakan misi kami untuk memimpin penerapan tehnologi masa depan yang berkelanjutan, keuntungan finansial bagi pengguna dan pertumbuhan ekonomi yang setara untuk kesejahteraan semua kalangan, termasuk karyawan, pelanggan, mitra usaha, pemasok, masyarakat dan kepedulian lingkungan.<br /><br /> Kami menyambut baik semua kalangan yang berbagi visi kami untuk bergabung dalam tujuan yang membawa perubahan ini.",
    ABOUT_CAROUSEL_TITLE: "Misi Kami di Mola - Fight Predictor",
    ABOUT_CAROUSEL_CONTENT: [
      {
        title: "Sepuluh Juta Kendaraan Dalam Sepuluh Tahun",
        content:
          "At Mola, We're on a mission to increase electric vehicle adoption by making EVs more affordable and accessible. We believe electric is the future of transportation. we believe that electric vehicles will be a game changer. They will be common vehicle in the next ten years, therefore this is our mission to make 10 million electric vehicles in 10 years.",
      },
      {
        title: "Mola Pengubah Permainan",
        content:
          "Our Mission is to be the system that moves people. Combining the beauty of design, the intelligence of software, and the kinetic energy created from perfectly balanced wheels. We’re creating a world where cities breathe easier and noise pollution is a thing of the past. Where driving is something everyone can enjoy. A world that recharges everyone it touches.",
      },
      {
        title: "Manfaat Untuk Lingkungan",
        content:
          "The vehicle that changed everything is back and better than ever. All-electric. No gas. And zero emissions. The key to getting the maximum benefit from your energy-efficient vehicle is ensuring that its power systems are running at peak efficiency.",
      },
    ]
  },
};
