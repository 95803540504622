import { db } from '../main';

export default {
  state: {
    pastEventList: new Map(),
    pastEventFightList: new Map(),
    fightResultByEvent: new Map(),
    predictionByEvent: [],
    pastFightList: []
  },
  mutations: {
    setState(state, payload) {
      var keys = Object.keys(payload);
      keys.forEach((key) => {
        state[key] = payload[key];
      });
    },
  },
  actions: {
    async getPastEventList({ commit }) {
        let mapEvent = new Map();
        let query = db
          .collection('events')
          .where('results_processed', '>', 0)
          .orderBy('results_processed', 'desc');
        try {
            const eventSnapshot = await query.get();
            if (!eventSnapshot.empty) {
            eventSnapshot.forEach((doc) => {
                const eventData = doc.data();
                // console.log(doc.data())
                eventData.event_id = doc.id;
                mapEvent.set(doc.id, eventData);
            });
            commit('setState', {
                pastEventList: mapEvent,
            });
            }
        }
        catch(Err){
            console.log(Err);
        }
    },
    async getPastFightList({ commit }, payload) {
      console.log(commit)
      console.log(payload)
      let query = db
        .collection('events')
        .doc(payload)
        .collection('fights')
        .orderBy('index', 'asc'); 
      query.get().then(
        (snapshot) => {
          console.log(
            `Received query snapshot of size ${snapshot.size}`
          );
          if (snapshot.empty) {
            console.log('No matching documents.');
            commit('setState', {
              pastFightList: [],
            });
            return;
          }
          // console.log(index)
          let fights = [];
          snapshot.forEach((doc) => {
            console.log(doc.id, '=>', doc.data())
            var obj = doc.data();
            obj.id = doc.id;
            obj.predictionInvalid = false;
            obj.predictionSubmitted = false;
            obj.changePrediction = false;
            obj.loading = false;
            // calculate predictions
            let homePredictions = doc.data().home.predictions_count; // !! Update SOON
            let awayPredictions = doc.data().away.predictions_count;
            let totalHome =
              homePredictions === 0
                ? 0
                : Math.round(
                    (100 / (homePredictions + awayPredictions)) *
                      homePredictions
                  );
            let totalAway =
              awayPredictions === 0
                ? 0
                : Math.round(
                    (100 / (homePredictions + awayPredictions)) *
                      awayPredictions
                  );
            obj.totalHome = totalHome;
            obj.totalAway = totalAway;
            // console.log(totalHome, totalAway)
            fights.push(obj);
          });

          commit('setState', {
            pastFightList: fights,
          });
        },
        (err) => {
          console.log(`Encountered error: ${err}`);
        }
      );
    },
    async getPredictionByEvent({commit}, payload){
        let predictions = [];
        let queryPredictions = db
          .collection('predictions')
          .where('event_doc_id', '==', payload.eventId).where('results_processed', '>=', 0);
        try {
          const predictionSnapshot = await queryPredictions.get();
          if (!predictionSnapshot.empty) {
            predictionSnapshot.forEach((doc) => {
              const predictionData = doc.data();
              predictionData.prediction_id = doc.id;
              predictions.push(predictionData);
            });
            commit('setState', {
              predictionByEvent: predictions,
            });
          }
        } catch (Err) {
          console.log(Err);
        }
    },
    async getFightResultByEvent({commit}, payload){
      console.log('getFightResultByEvent')
        let fights = new Map();
        let query = db
          .collection('events')
          .doc(payload.eventId)
          .collection('fights')
          .orderBy('start', 'asc'); 
        let snapshot = await query.get();
        console.log('getFightResultByEvent', snapshot);
        snapshot.forEach(doc => {
            const obj = doc.data();
            obj.fight_id = doc.id;
            obj.name = obj.home.name + ' Vs. ' + obj.away.name
            fights.set(doc.id, obj);
        })
        commit('setState', {
          fightResultByEvent: fights,
        });
    },
    async repairPredictionData({commit},payload){
        console.log(payload)
        try {
          if (Array.isArray(payload)) {
            //Bulk Update
          } else {
            db.collection('predictions')
              .doc(payload.predictionId)
              .update(payload.prediction);
          }
          commit('setState', {
            recalculateMessage: "",
          });
        } catch (err) {
          console.log(err);
        }
    }
  },
  getters: {},
};
