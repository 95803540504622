import firebase from 'firebase';
import { db } from '../main';
import MolaMfpAPI from '../clients/MolaMfpAPI';
import { isPrivateRelay } from '../const/functions/isPrivateRelay';

export default {
  state: {
    user:
      localStorage.getItem('mfp-user') === null
        ? null
        : JSON.parse(localStorage.getItem('mfp-user')),
    isLoggedIn: localStorage.getItem('mfp-user') === null ? false : true,
    mola_subscription: 0,
    mola_subscription_message: '',
    mola_subscription_firebase: 0,
    role: 0,
    uid: '',
    name: '',
    about_me: '',
    full_name: '',
    address: '',
    date_of_birth: '',
    gender: 0,
    email: '',
    email_molatv: '',
    avatar: '',
    status: '',
    email_verified: false,
    phone_verified: false,
    id_type: '',
    acc_level: 10,
    acc_status: 1,
    terms_accepted: false,
    language: 'id',
    dark: true,
    phone_number: 0,
    created: 0,
    modified: 0,
    last_login: 0,
    mola_points: 0,
    referral_code: '',
    mola_points_updated: 0,
    total_points_won: 0,
    weekly_points_won: 0,
    provider:
      localStorage.getItem('mfp-user-providers') === null
        ? null
        : JSON.parse(localStorage.getItem('mfp-user-providers')),
    resume: [],
    auth_message: '',
    recap_overall_point: 0,
    recap_overall_rank: 0,
    recap_weekly_point: 0,
    recap_weekly_rank: 0,
    deviceId: localStorage.getItem('mfp-device-id') === null
      ? ''
      : JSON.parse(localStorage.getItem('mfp-device-id')),
    //molaSubscription: localStorage.getItem("molaSubscription") === null ? null : JSON.parse(localStorage.getItem("molaSubscription"))
  },
  mutations: {
    setUser(state, payload) {
      console.log(payload);
      state.user = payload;
      state.isLoggedIn = payload === null ? false : true;
      localStorage.setItem('mfp-user', JSON.stringify(payload));
    },
    setAvatar(state, payload) {
      state.avatar = payload;
    },
    setState(state, payload) {
      var keys = Object.keys(payload);
      keys.forEach((key) => {
        state[key] = payload[key];
      });
    },
    SetBgImageState(state, payload) {
      var keys = Object.keys(payload);
      keys.forEach((key) => {
        state[key] = payload[key];
      });
    },
    SetUserState(state, payload) {
      var keys = Object.keys(payload);
      keys.forEach((key) => {
        state[key] = payload[key];
      });
    },
    setRole(state, payload) {
      state.role = payload;
    },
    setProvider(state, payload) {
      state.provider = payload;
      localStorage.setItem('mfp-user-providers', JSON.stringify(payload));
    },
    setUserName: (state, payload) => {
      state.user['name'] = payload;
      const userData = JSON.parse(localStorage.getItem('mfp-user'));
      userData['name'] = payload;
      localStorage.setItem('mfp-user', JSON.stringify(userData));
    },
    setUserDetails: (state, payload) => {
      console.log('setUserDetails');
      state.uid = payload.uid ?? '';
      state.name = payload.name ?? '';
      state.about_me = payload.about_me ?? '';
      state.full_name = payload.full_name ?? '';
      state.address_1 = payload.address_1 ?? '';
      state.address_2 = payload.address_2 ?? '';
      state.city = payload.city ?? '';
      state.country = payload.country ?? '';
      state.zip = payload.zip ?? '';
      state.date_of_birth = payload.date_of_birth ?? '';
      state.gender = payload.gender ?? 'N';
      state.email = payload.email ?? '';
      state.avatar = payload.avatar ?? '';
      state.status = payload.status ?? '';
      state.email_verified = payload.email_verified ?? false;
      state.phone_verified = payload.phone_verified ?? false;
      state.acc_level = payload.acc_level ?? 10;
      state.terms_accepted = payload.terms_accepted ?? false;
      state.language = 'id';//payload.language ?? 'en';
      state.dark = true;
      state.created = payload.created ?? 0;
      state.modified = payload.modified ?? 0;
      state.last_login = payload.last_login ?? 0;
      state.mola_points = payload.mola_points ?? 0;
      state.total_points_won = payload.total_points_won ?? 0;
      state.mola_points_updated = payload.mola_points_updated ?? 0;
      state.weekly_points_won = payload.weekly_points_won ?? 0;
      state.phone_number = payload.phone_number ?? 0;
      state.mola_subscription_firebase = payload.mola_subscription ?? 0;
      state.about_me = payload.about_me ?? '';
      state.referral_code = payload.referral_code ?? '';
      state.email_molatv = payload.email_molatv ?? '';
    },
    setUserSession(state, payload){
      state.deviceId = payload;
      localStorage.setItem('mfp-device-id', JSON.stringify(payload));
    }
  },
  actions: {
    //Create account when input password
    signUserUp({ commit, getters }, payload) {
      commit('setLoading', true);
      commit('clearError');
      firebase
        .auth()
        .createUserWithEmailAndPassword(payload.email, payload.password)
        .then((result) => {
          const user = result.user;
          console.log(user);
          if (user) {
            const providers = user.providerData.map((Q) => Q.providerId);
            const currentProvider =
              getters.provider.currentProvider || 'Email-Password';
            commit('setProvider', {
              currentProvider: currentProvider,
              providers: providers,
            });
          }
        })
        .catch((error) => {
          commit('setState', {
            auth_message: error.message,
          });
          console.log(error);
        });
    },
    //Email & Password
    signUserIn({ commit, dispatch }, payload) {
      commit('setLoading', true);
      commit('clearError');
      firebase
        .auth()
        .signInWithEmailAndPassword(payload.email, payload.password)
        .then((result) => {
          console.log(result);
          const user = result.user;
          if (user) {
            const providers = user.providerData.map((Q) => Q.providerId);
            const newUser = {
              id: user.uid,
              name: user.displayName,
              email: user.email,
              isLoggedIn: true,
            };
            const provider = {
              providers: providers,
              currentProvider: 'Email-Password',
            };
            commit('setLoading', false);
            commit('setUser', newUser);
            dispatch('insertUserSession', {
              mfp_uid: user.uid,
              isLoggedIn: true,
            })
            commit('setProvider', provider);
          }
        })
        .catch((error) => {
          commit('setState', {
            auth_message: error.message,
          });
          console.log(error);
        });
    },
    //Pop Up
    signUserInGoogle({ commit, dispatch }) {
      commit('setLoading', true);
      commit('clearError');
      firebase
        .auth()
        .signInWithPopup(new firebase.auth.GoogleAuthProvider())
        .then((result) => {
          console.log('result', result);
          const user = result.user;
          if (user) {
            const providers = user.providerData.map((Q) => Q.providerId);
            const newUser = {
              id: user.uid,
              name: user.displayName,
              email: user.email,
              isLoggedIn: true,
            };
            const provider = {
              providers: providers,
              currentProvider: 'Google-Auth',
            };
            console.log(user.email);
            dispatch('linkSignIn', {
              email: user.email,
              password: user.uid,
            });
            commit('setUser', newUser);
            dispatch('insertUserSession', {
              mfp_uid: user.uid,
              isLoggedIn: true,
            })
            commit('setProvider', provider);
          }
        })
        .catch((error) => {
          commit('setState', {
            auth_message: error.message,
          });
          console.log(error);
        });
    },
    signUserInApple({ commit, dispatch }) {
      commit('setLoading', true);
      commit('clearError');
      var provider = new firebase.auth.OAuthProvider('apple.com');
      provider.addScope('email');
      provider.addScope('name');
      provider.setCustomParameters({
        locale: 'id'
      });
      firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
          console.log('result', result);
          const user = result.user;
          if (user) {
            const providers = user.providerData.map((Q) => Q.providerId);
            const newUser = {
              id: user.uid,
              name: user.displayName,
              email: user.email,
              isLoggedIn: true,
            };
            const provider = {
              providers: providers,
              currentProvider: 'Apple-Auth',
            };
            console.log(user.email);
            dispatch('linkSignIn', {
              email: user.email,
              password: user.uid,
            });
            commit('setUser', newUser);
            dispatch('insertUserSession', {
              mfp_uid: user.uid,
              isLoggedIn: true,
            })
            commit('setProvider', provider);
          }
        })
        .catch((error) => {
          commit('setState', {
            auth_message: error.message,
          });
          console.log(error);
        });
    },
    signInWithLink: ({ commit, dispatch }, payload) => {
      firebase
        .auth()
        .signInWithEmailLink(payload.email, payload.link)
        .then(async (result) => {
          const user = result.user;
          if (user) {
            const providers = user.providerData.map((Q) => Q.providerId);
            const newUser = {
              id: user.uid,
              name: user.displayName,
              email: user.email,              
              isLoggedIn: true,
            };
            const provider = {
              providers: providers,
              currentProvider: 'Email-Password',
            };
            if (payload.new) {
              if (result.user !== null && result.user !== undefined) {
                try {
                  console.log('Update PW');
                  console.log(result.user);
                  await firebase
                    .auth()
                    .currentUser.updatePassword(result.user.uid);
                } catch (err) {
                  commit('setError', err);
                  console.log(err);
                }
              } else {
                await firebase
                  .auth()
                  .currentUser.updatePassword(result.user.uid);
              }
            }
            commit('setUser', newUser);
            dispatch('insertUserSession', {
              mfp_uid: user.uid,
              isLoggedIn: true,
            })
            commit('setLoading', false);
            commit('setProvider', provider);
            localStorage.removeItem('emailForSignIn');
          }
        })
        .catch((error) => {
          localStorage.removeItem('emailForSignIn');
          console.log(error);
          commit('setState', {
            auth_message: error.message,
          });
        });
    },
    linkSignIn({ commit }, payload) {
      console.log(payload);
      var credential = firebase.auth.EmailAuthProvider.credential(
        payload.email,
        payload.password
      );
      firebase
        .auth()
        .currentUser.linkWithCredential(credential)
        .then((usercred) => {
          var user = usercred.user;
          console.log('Account linking success', user);
        })
        .catch((error) => {
          console.log('Account linking error', error);
        });
      commit('setLoading', false);
    },
    async logout({ commit, state }) {
      const deviceId = state.deviceId;
      console.log(deviceId)
      localStorage.removeItem('mfp-user');
      localStorage.removeItem('mfp-user-providers');
      commit('setState', {
        user: null,
        isLoggedIn: false,
        mola_subscription: 0,
        mola_subscription_message: '',
        mola_subscription_firebase: 0,
        role: 0,
        uid: '',
        name: '',
        about_me: '',
        full_name: '',
        address: '',
        date_of_birth: '',
        gender: 'N',
        email: '',
        avatar: '',
        status: '',
        email_verified: false,
        phone_verified: false,
        id_type: '',
        acc_level: '',
        terms_accepted: false,
        language: 'id',
        phone_number: 0,
        created: 0,
        modified: 0,
        last_login: 0,
        mola_points: 0,
        provider: null,
        referral_code: ''
      });

      if(deviceId !== '') {
        await db.collection('user_sessions')
          .doc(deviceId)
          .set({
            isLoggedIn: false,
            modified: new Date().getTime()
          }, {merge: true})
      }
      localStorage.removeItem('mfp-device-id')
      await firebase.auth().signOut();
      // Clear userPredictionsArr Array or reload...
      // commit('setUserPredictionsArr', [])
      // commit('setEventList', [])
      //
    },
    async getMolaSubscription({ commit, state, getters }) {
      console.log('getMolaSubscription', state)
      const email = isPrivateRelay(getters.user.email) ? state.email_molatv : getters.user.email;
      //const email2 = 'ribkahyesikas@gmail.com';//'sbr@mola.tv';
      console.log(email);
      let res;
      let payload;
      try {
        const response = await MolaMfpAPI.getSubscriptionStatus(email); //await axios.get(`http://localhost:5001/api/v1/users/mola?email=${email}`);
        console.log(response);
        res = response;
      } catch (err) {
        console.log(err);
        res = null;
      }
      if (res && res.status === 200) {
        payload = {
          mola_subscription: res.data.data.mola_subscription_status,
          mola_subscription_message: res.data.data.mola_subscription_message,
        };
      } else {
        payload = {
          mola_subscription: 0,
          mola_subscription_message: 'Not a Mola Subscriber',
        };
      }
      console.log(payload);
      commit('setState', payload);
      console.log(payload.mola_subscription !== getters.molaSubFirebase);
      console.log(getters.molaSubFirebase);
      if (payload.mola_subscription !== getters.molaSubFirebase) {
        await db.collection('users').doc(getters.user.id).update(payload);
        commit('setState', {
          mola_subscription_firebase: payload.mola_subscription,
        });
      }
    },
    logReferralToDoc ({ commit }, payload) {
      commit('setLoading', true);
      db.collection('user_referrals')
        .doc(payload.doc_id)
        .set(payload, { merge: true})
        .then(() => {
          console.log('Referral in bucket updated!');
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getRole({ commit, getters }, payload) {
      let role = 0;
      if (payload) {
        role = payload;
      }
      if (getters.user !== null) {
        const doc = await db.collection('roles').doc(getters.user.id).get();
        if (doc.exists) {
          role = doc.data().accType;
        }
      }
      commit('setState', { role: role });
    },
    async getUserDetail({ commit, getters }) {
      if (getters.user !== null) {
        try {
          const doc = await db.collection('users').doc(getters.user.id).get();
          if (doc.exists) {
            commit('setUserDetails', doc.data());
            commit('SetCurrentLanguage', 'id')//doc.data().language ?? 'en');
          }
        } catch (err) {
          console.log(err);
        }
      }
    },
    async getMolaPointsLedger({ commit, state }, payload) {
      console.log('####### getMolaPointsLedger #######');
      console.log(state);
      console.log(payload);
      commit('setLoading', payload);
      let res;
      let mola_points = 0;
      let mola_points_updated = 0;
      // let weekly_points_won = 0;
      try {
        // According to mola_points_updated in fb
        const response = await MolaMfpAPI.getMolaPointsFromLedger(payload.uid);
        console.log(response);
        res = response;
        for (var r in response.data) {
          mola_points += response.data[r].points;
        }
        // let today = new Date().getTime() - 1000 * 3600 * 24 * 7
        /* for (var w in response.data) {
          if (response.data[w].transaction_timestamp > today) {
           weekly_points_won += response.data[r].points;
          }
        } */
        console.log('mola_points')
        console.log(mola_points)
        mola_points_updated = new Date().getTime() + 1000 * 3600 * 24 // 1 day
        let updateObj = {
          mola_points: mola_points,
          mola_points_updated: mola_points_updated
          // weekly_points_won: weekly_points_won
        };
        await db.collection('users').doc(state.user.id).update(updateObj);
        commit('setState', { mola_points: mola_points, mola_points_updated: mola_points_updated });
      } catch (err) {
        console.log(err);
        res = null;
      }
      if (res && res.status === 200) {
        payload = {
          mola_points: mola_points,
          mola_points_message: 'Total Point From Ledger',
        };
      } else {
        payload = {
          mola_subscription: 0,
          mola_points_message: 'No Mola Points',
        };
      }
    },
    async insertUserSession({commit}, payload) {
      let postkey = db.collection('user_sessions').doc();
      payload['created'] = new Date().getTime()
      payload['modified'] = 0
      db.collection('user_sessions')
        .doc(postkey.id)
        .set(payload)
        .then(() => {
          console.log('user_sessions in bucket created');
          commit('setUserSession', postkey.id)
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getUserRecap({ commit, state }) {
      let res;
      const today = new Date();
      const cache = localStorage.getItem('mfp-user-recap') === null
          ? null
          : JSON.parse(localStorage.getItem('mfp-user-recap'));
      console.log('cache',cache);
      if (cache !== null) {
        if (cache.expired >= today.getTime()) {
          commit('setState', {
            recap_overall_point: cache.recap_overall_point,
            recap_overall_rank: cache.recap_overall_rank,
            recap_weekly_point: cache.recap_weekly_point,
            recap_weekly_rank: cache.recap_weekly_rank,
          });
          return;
        }
      }
      try {
        const response = await MolaMfpAPI.getUserRecap(state.user.id);
        res = response;
      } catch (err) {
        console.log(err);
        res = null;
      }
      console.log(res);
      if (res && res.status === 200) {
        const expiredDate = new Date(
          today.setDate(today.getDate() - today.getDay() + 6)
        );
        const payload = {
          recap_overall_point: res.data.total_points_won,
          recap_overall_rank: res.data.overall_rank,
          recap_weekly_point: res.data.weekly_points_won,
          recap_weekly_rank: res.data.weekly_rank,
          expired: expiredDate.getTime(),
        };
        localStorage.setItem('mfp-user-recap', JSON.stringify(payload));
        commit('setState', payload);
      }else{
        commit('setState', {
          recap_overall_point: 0,
          recap_overall_rank: 0,
          recap_weekly_point: 0,
          recap_weekly_rank: 0,
        });
      }
    },
    // async getUserNotificationCount({commit, state}, payload){
    //   db.collection('notifications')
    //     .where("receiver_uid", "==", payload.uid)
    //     .where("status", "==", 0)
    //     .onSnapshot(item => {
    //       item.size
    //     })
    // }
  },
  getters: {
    user(state) {
      return state.user;
    },
    isLoggedIn(state) {
      return state.isLoggedIn;
    },
    role(state) {
      return state.role;
    },
    provider(state) {
      return state.provider;
    },
    molaSubFirebase(state) {
      return state.mola_subscription_firebase;
    },
    authMessage(state) {
      return state.auth_message;
    },
  },
};